import PropTypes from "prop-types";
import React from "react";
import { AccordionHeader } from "reactstrap";

import "./CaseManagement.scss";

export const CustomAccordionHeader = ({
    containerClassName = "",
    innerClassName = "",
    subContainerClassName = "",
    headerCount,
    headerText = "data",
    status,
    targetId
}) => {
    return (
        <AccordionHeader targetId={targetId}>
            <div className={containerClassName}>
                <div className={innerClassName}>
                    <div>{headerCount}</div>
                    <p>{headerText}</p>
                </div>

                <div className={subContainerClassName}>
                    <p>
                        Status:{" "}
                        <span
                            className={`header-color ${
                                status === "Open" ? "open-status-color" : ""
                            }`}
                        >
                            {status}{" "}
                        </span>
                    </p>
                </div>
            </div>
        </AccordionHeader>
    );
};

// handling prop types
CustomAccordionHeader.propTypes = {
    containerClassName: PropTypes.any,
    innerClassName: PropTypes.any,
    subContainerClassName: PropTypes.any,
    headerCount: PropTypes.any,
    headerText: PropTypes.any,
    status: PropTypes.any,
    targetId: PropTypes.any,
    documentData: PropTypes.object
};
