import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {
    Col,
    Row,
    Card,
    AccordionBody,
    Label,
    AccordionItem,
    UncontrolledAccordion
} from "reactstrap";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { CustomAccordionHeader } from "./Accordion";
import TrademarkDetail from "./TrademarkDetail";
import { CaseRejection } from "components/CaseRejectionModal";
import DocumentViewerModal from "./DocumentViewerModal";

import ESignatureIcon from "../../assets/images/aiAPaiT/case-mangement/e-signature-icon.png";
import DateIcon from "../../assets/images/aiAPaiT/case-mangement/date-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";
import {
    accordionStatus,
    buttonTitle,
    documentTypePayload,
    stepTitle,
    caseStatus
} from "./Constants";
import { tmcrDispatch } from "api/api.service";
import { truncateString } from "utils/commonUtils";

const PENDING = "Pending";
const PAID = "Paid";
const CHARACTER_LIMIT = 20;

export default function POAApplicationCompleted({
    defaultOpenAccord = [],
    title = "",
    isCompleted,
    status = accordionStatus.COMPLETED,
    targetId = "",
    isPA = false,
    processNO,
    isReject = false,
    isAwaiting,
    data_description,
    isFormTwoVisible = false,
    nextStepTargetId = "",
    nextStepHandler = () => {},
    refetchStepsAPIHandler,
    fileDownload = false,
    rejectionReason = "",
    isSubmittingStep = false,
    deliveryStatus = false,
    isDispatch = false,
    isRefetchingRequired = () => {}
}) {
    const history = useHistory();
    const params = useParams();
    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false);
    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [dispatchLoading, setDispatchLoading] = useState(false);

    // input field change handler
    const handleFeeChange = (e) => {
        console.log(e);
    };
    // rejection modal close handling
    const rejectionModalCloseHandler = (isReject) => {
        isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
        setRejectionSuccessModal(false);
    };

    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl);
        setIsPdfViewerModalActive(true);
    };

    // pdf/image document view modal close handler
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("");
        setIsPdfViewerModalActive(false);
    };

    // move to next step
    const handleMoveToNextStep = (e) => {
        nextStepHandler(nextStepTargetId);
    };

    const handleDispatchActions = async () => {
        try {
            setDispatchLoading(true);
            await tmcrDispatch(params?.id, { isDispatch: true });
            isRefetchingRequired(true);
        } finally {
            setDispatchLoading(false);
        }
    };

    return (
        <>
            <div
                className={`case-detail-accordian-signature-con ${
                    isReject && "accordion-disable-class"
                }`}
            >
                <UncontrolledAccordion defaultOpen={defaultOpenAccord} stayOpen>
                    <AccordionItem>
                        <CustomAccordionHeader
                            containerClassName="case-detail-accordian-container"
                            innerClassName="case-detail-accordian-header"
                            subContainerClassName="case-detail-accordian-header-status"
                            headerCount={processNO}
                            headerText={title}
                            status={status}
                            targetId={targetId}
                        />

                        <AccordionBody
                            accordionId={targetId}
                            className="case-detail-accordian-body-con"
                        >
                            <Card>
                                {data_description &&
                                data_description?.documents?.length > 0 &&
                                Object.keys(data_description).length > 0 ? (
                                    <>
                                        <Row>
                                            {isPA && (
                                                <>
                                                    <Col md={6}>
                                                        <div className="case-detail-accordian-signature-body">
                                                            Power of Attorney field
                                                        </div>
                                                        <TrademarkDetail
                                                            documentData={
                                                                data_description?.documents[0]
                                                                    ?.documentType ===
                                                                documentTypePayload.TRADEMARK_POWER_ATTORNEY
                                                                    ? data_description?.documents[0]
                                                                          ?.media
                                                                    : data_description?.documents[1]
                                                                          ?.media
                                                            }
                                                            documentViewerHandler={
                                                                documentViewerHandler
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="case-detail-accordian-signature-body">
                                                            Application field
                                                        </div>
                                                        <TrademarkDetail
                                                            documentData={
                                                                data_description?.documents[0]
                                                                    ?.documentType ===
                                                                documentTypePayload.TRADEMARK_APPLICATION
                                                                    ? data_description?.documents[0]
                                                                          ?.media
                                                                    : data_description?.documents[1]
                                                                          ?.media
                                                            }
                                                            documentViewerHandler={
                                                                documentViewerHandler
                                                            }
                                                        />
                                                    </Col>
                                                </>
                                            )}
                                            <Col
                                                md={
                                                    data_description?.address &&
                                                    data_description?.deliveryStatus
                                                        ? 6
                                                        : 0
                                                }
                                            >
                                                {!isPA && (
                                                    <>
                                                        <Col md={6}>
                                                            <div className="case-detail-accordian-signature-body">
                                                                {title === stepTitle.PUBLICATION
                                                                    ? "Trademark Publication Form"
                                                                    : "Trademark Registration"}
                                                            </div>
                                                            <TrademarkDetail
                                                                documentData={
                                                                    data_description?.documents[0]
                                                                        .media
                                                                }
                                                                documentViewerHandler={
                                                                    documentViewerHandler
                                                                }
                                                            />
                                                        </Col>
                                                    </>
                                                )}

                                                <Col md={12}>
                                                    {!fileDownload ? (
                                                        <div className="case-detail-accordian-body">
                                                            <div>{"Step Fee"}</div>
                                                            <p>{`$${data_description?.amount}`}</p>
                                                        </div>
                                                    ) : (
                                                        deliveryStatus && (
                                                            <div className="case-detail-accordian-body">
                                                                <div>{"Shipping Fee"}</div>
                                                                <p>
                                                                    $
                                                                    {data_description?.amount
                                                                        ? data_description?.amount
                                                                        : "-"}
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                    {data_description?.payment?.updatedAt ? (
                                                        <div className="case-detail-accordian-body">
                                                            <div>{"Payment Date & Time"}</div>
                                                            <p>
                                                                {moment(
                                                                    data_description?.payment
                                                                        ?.updatedAt
                                                                ).format(
                                                                    "DD MMMM, YYYY [at] h:mm A"
                                                                )}
                                                            </p>
                                                        </div>
                                                    ) : null}

                                                    <div className="bg-white p-3 pt-4 case-details-accordian-date-field">
                                                        <Label>
                                                            {!fileDownload
                                                                ? "Fee Deadline"
                                                                : "Renewal Date"}
                                                        </Label>
                                                        <div className="mb-3">
                                                            <TextInput
                                                                name={"feesDeadline"}
                                                                type="date"
                                                                className="hide-icon"
                                                                onChange={handleFeeChange}
                                                                value={moment(
                                                                    data_description?.expiryDate
                                                                ).format("YYYY-MM-DD")}
                                                                autoFocus
                                                                iconFile={DateIcon}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Col>
                                            <Col
                                                md={
                                                    data_description?.address &&
                                                    data_description?.deliveryStatus
                                                        ? 6
                                                        : 0
                                                }
                                            >
                                                {data_description &&
                                                    data_description?.address &&
                                                    data_description?.deliveryStatus && (
                                                        <Col md={6}>
                                                            <div className="shipping-details">
                                                                <div className="sub-container">
                                                                    {"Shipping Details"}
                                                                </div>
                                                                <div className="mt-2">
                                                                    {"Name: "}
                                                                    <span>
                                                                        {
                                                                            data_description?.customerName
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className="mt-2">
                                                                    {"City: "}
                                                                    <span>
                                                                        {data_description?.city}
                                                                    </span>
                                                                </div>

                                                                <div className="mt-2">
                                                                    {"Country: "}
                                                                    <span>
                                                                        {
                                                                            data_description
                                                                                ?.country?.name
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className="mt-2">
                                                                    {"Address: "}
                                                                    <span>
                                                                        {data_description?.address}
                                                                    </span>
                                                                </div>
                                                                <div className="mt-2">
                                                                    {"PhoneNumber: "}
                                                                    <span>
                                                                        {data_description?.countryCode
                                                                            ? data_description?.countryCode +
                                                                              data_description?.phoneNumber
                                                                            : data_description?.phoneNumber}
                                                                    </span>
                                                                </div>

                                                                <div className="mt-2">
                                                                    {"MailBox: "}
                                                                    <span>
                                                                        {data_description?.mailBox
                                                                            ? data_description?.mailBox.substring(
                                                                                  0,
                                                                                  26
                                                                              )
                                                                            : ""}
                                                                    </span>
                                                                </div>
                                                                <div className="mt-2">
                                                                    {"streetName: "}
                                                                    <span>
                                                                        {
                                                                            data_description?.streetName
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="mt-2">
                                                                    {"zipCode: "}
                                                                    <span>
                                                                        {data_description?.zipCode}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )}
                                            </Col>
                                            <Row>
                                                {isDispatch &&
                                                    data_description?.shippingFeeStatus !==
                                                        PENDING && (
                                                        <>
                                                            <Row>
                                                                <div className="accordian-body-footer-btn">
                                                                    <CustomButton
                                                                        title={buttonTitle.DISPATCH}
                                                                        className="trademark-body-footer-accept-button me-3 shadow-none"
                                                                        color="#704DE7"
                                                                        outline={true}
                                                                        type={"submit"}
                                                                        onClick={
                                                                            handleDispatchActions
                                                                        }
                                                                        loading={dispatchLoading}
                                                                    />
                                                                </div>
                                                            </Row>
                                                        </>
                                                    )}
                                            </Row>

                                            {isPA && (
                                                <Col md={12}>
                                                    <div className="pa-check-box pa-view-only-checkbox">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name={"legalisation"}
                                                                defaultChecked={
                                                                    data_description?.legalisation
                                                                }
                                                            />
                                                            {"Legalisation"}
                                                        </label>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                        {isAwaiting ? (
                                            <>
                                                <hr />
                                                <Row>
                                                    <div className="e-signature-footer-btn">
                                                        <img
                                                            src={ESignatureIcon}
                                                            height={40}
                                                            width={40}
                                                        />
                                                        <div className="accordian-body-footer-text ">
                                                            Case is in progress and waiting for
                                                            customer response
                                                        </div>
                                                    </div>
                                                </Row>
                                            </>
                                        ) : null}
                                        {data_description?.shippingFeeStatus === PENDING &&
                                        deliveryStatus ? (
                                            <>
                                                <hr />
                                                <Row>
                                                    <div className="e-signature-footer-btn">
                                                        <img
                                                            src={ESignatureIcon}
                                                            height={40}
                                                            width={40}
                                                        />

                                                        <div className="text-left accordian-body-footer-text">
                                                            {" Waiting for shipment fee."}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </>
                                        ) : null}
                                        {!isCompleted && (
                                            <>
                                                <hr />
                                                <Row>
                                                    <div className="accordian-body-footer-btn">
                                                        <CustomButton
                                                            title={buttonTitle.MOVE_TO_NEXT_STEP}
                                                            className="trademark-body-footer-accept-button shadow-none me-3"
                                                            onClick={handleMoveToNextStep}
                                                            disabled={isFormTwoVisible}
                                                        />
                                                        <CustomButton
                                                            title={buttonTitle.REJECT}
                                                            className="trademark-body-footer-reject-button shadow-none"
                                                            color="#E64A4A"
                                                            onClick={() => {
                                                                setRejectionSuccessModal(true);
                                                            }}
                                                            disabled={isSubmittingStep}
                                                        />
                                                    </div>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p className="data-not-found-message">Data Not Found</p>
                                    </>
                                )}
                            </Card>
                            {isReject ? (
                                <>
                                    <Card className="p-3 pt-0">
                                        <Row>
                                            <hr />
                                            <Col className="pt-2">
                                                <p className="rejection-reason">
                                                    <span className="rejection-typo-heading">
                                                        Rejection reason:{" "}
                                                    </span>
                                                    {rejectionReason || "-"}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            ) : null}
                            <DocumentViewerModal
                                modalActivator={isPdfViewerModalActive}
                                modalCloseHandler={pdfViewerModalCloseHandler}
                                modalData={pdfDataLink}
                                isDataFetching={false}
                            />
                            <CaseRejection
                                modalCloseHandler={rejectionModalCloseHandler}
                                modalActivator={rejectionSuccessModal}
                                stepId={data_description}
                                setIsRefetchingRequired={refetchStepsAPIHandler}
                                title={title}
                            />
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            </div>
        </>
    );
}

// handling prop types
POAApplicationCompleted.propTypes = {
    openAccord: PropTypes.string,
    toggleButtonHandler: PropTypes.func,
    title: PropTypes.string,
    targetId: PropTypes.string,
    accordId: PropTypes.string,
    isPA: PropTypes.bool,
    processNO: PropTypes.string
};
