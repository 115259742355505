import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Row, Container } from "reactstrap";
import { CardStats } from "../../components/CardStats";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import Earnings from "./Earnings";
import { METATAGS } from "../../constants";

import "./Dashboard.scss";
import { CaseDetail } from "components/CaseDetail";
import UserIcon from "../../assets/images/aiAPaiT/dasboard-user-icon.svg";
import TotalCasesIcon from "../../assets/images/aiAPaiT/dashboard-case-icon.png";
import CountryIcon from "../../assets/images/aiAPaiT/dasboard-country-icon.png";
import EarningIcon from "../../assets/images/aiAPaiT/dashboard-earning-icon.png";

import { dashboardEarnings, getDashboardTrademark } from "api/api.service";
import { Loader } from "components/Loader";

const Dashboard = () => {
    const [data, setData] = useState(null);
    const [recentCases, setRecentCases] = useState(null);
    const [loading, setLoading] = useState(false);

    // fetching data from API
    useEffect(async () => {
        setLoading(true);
        const res = await dashboardEarnings();
        setData(res);
        const trademarkResponse = await getDashboardTrademark();
        setRecentCases(trademarkResponse);
        setLoading(false);
    }, []);

    // dashboard stats box's data
    const reports = [
        {
            title: "Total Users",
            iconClass: "bx bx-book-bookmark",
            description: data?.users,
            iconUrl: UserIcon
        },
        {
            title: "Total Cases",
            iconClass: "bx bxs-file-blank",
            description: data?.cases,
            iconUrl: TotalCasesIcon
        },
        {
            title: "Total Countries",
            iconClass: "bx bxs-comment-dots",
            description: data?.countries,
            iconUrl: CountryIcon
        },
        {
            title: "Total Earnings",
            iconClass: "bx bx-time",
            description: <>{data?.earnings ? `$${data?.earnings}` : "$0"}</>,
            options: false,
            iconUrl: EarningIcon
        }
    ];

    return (
        <React.Fragment>
            <div className="p-3 dashboard-container">
                <MetaTags>
                    <title>{METATAGS.DASHBOARD}</title>
                </MetaTags>
                <Container fluid>
                    <Row>
                        {reports.map((report, key) => (
                            <Col md={6} xxl={3} key={"_col_" + key}>
                                <CardStats
                                    showOptions={report.options}
                                    titleClass="report-title"
                                    report={report}
                                    loading={loading}
                                    descriptionClass="report-description"
                                />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Earnings />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="card-title">Recent Cases</div>

                                        {recentCases ? (
                                            <Link
                                                to={APP_ROUTES.CASE_MANAGEMENT}
                                                className="dashboard-view-all"
                                            >
                                                View All
                                            </Link>
                                        ) : null}
                                    </div>
                                    <Row>
                                        {loading ? (
                                            <Loader />
                                        ) : recentCases?.data?.length === 0 ? (
                                            <h5
                                                className="d-flex justify-content-center"
                                                style={{ color: "black" }}
                                            >
                                                Data Not Found
                                            </h5>
                                        ) : (
                                            recentCases?.data?.map((res, index) => (
                                                <Col md={6} xxl={4} key={index}>
                                                    <CaseDetail data={res} />
                                                </Col>
                                            ))
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
