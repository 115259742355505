import React from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";

import { CustomButton } from "components/CustomButton";

const HeaderWithButton = ({
    data,
    isButton = true,
    isCustomerDetails = false,
    isSettingDetails = false,
    className = "",
    cardClassName = "",
    isUserSuspended,
    isSuspensionLoading,
    handleButtonClick = () => {}
}) => {
    return (
        <>
            <div className={`case-detail-container pb-0 ${className}`}>
                <Card className={`case-detail-main-container ${cardClassName}`}>
                    <div className="cm-header-con">
                        {isCustomerDetails && (
                            <>
                                <div className="case-detail-form-fields-container">
                                    <p>Full Name</p>
                                    <div>{data?.name || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container">
                                    <p>Total Cases</p>
                                    <div>{data?.total || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container">
                                    <p>Phone Number</p>
                                    <div>{`${data?.countryCode}${data?.phoneNumber}` || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container">
                                    <p>Total Spendings</p>
                                    <div>{`$${data?.totalSpendings}` || "-"}</div>
                                </div>
                            </>
                        )}

                        {isSettingDetails && !isCustomerDetails && (
                            <>
                                <div className="case-detail-form-fields-container">
                                    <p>User Name</p>
                                    <div>{data?.name || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container">
                                    <p>Email Address</p>
                                    <div>{data?.email || "-"}</div>
                                </div>
                                {data?.type === "SUBADMIN" && (
                                    <div className="case-detail-form-fields-container">
                                        <p>Country</p>
                                        <div>
                                            {(data?.userCountries.length &&
                                                data.userCountries.join(", ")) ||
                                                "-"}
                                        </div>
                                    </div>
                                )}
                                <div className="case-detail-form-fields-container">
                                    <p>Phone Number</p>
                                    <div>{`${data?.countryCode}${data?.phone}` || "-"}</div>
                                </div>
                            </>
                        )}

                        {!isCustomerDetails && !isSettingDetails && (
                            <>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Customer Name</p>
                                    <div>{data?.customerName || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Department Name</p>
                                    <div>{data?.adminName || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Country</p>
                                    <div>{data?.countryName || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Customer’s Phone Number</p>
                                    <div>{data?.customerPhone || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Case ID</p>
                                    <div>{data?.caseId || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Case Title</p>
                                    <div>{data?.caseTitle || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields">
                                    <p>Department’s Email</p>
                                    <div>{data?.adminEmail || "-"}</div>
                                </div>
                                <div className="case-detail-form-fields-container customer-details-header-fields"></div>
                            </>
                        )}

                        {isButton && (
                            <div className="case-detail-form-fields-container">
                                <CustomButton
                                    title={isUserSuspended === "SUSPENDED" ? "Unsuspend" : "Suspend"}
                                    className="cd-header-btn shadow-none"
                                    color="#704DE7"
                                    outline
                                    onClick={handleButtonClick}
                                    disabled={isSuspensionLoading}
                                    loading={isSuspensionLoading}
                                />
                            </div>
                        )}
                    </div>
                </Card>
            </div>
        </>
    );
};

HeaderWithButton.propTypes = {
    data: PropTypes.object,
    isButton: PropTypes.bool,
    isCustomerDetails: PropTypes.bool,
    isSettingDetails: PropTypes.bool,
    className: PropTypes.string,
    cardClassName: PropTypes.string,
    handleButtonClick: PropTypes.func,
    isUserSuspended: PropTypes.string,
    isSuspensionLoading: PropTypes.bool
};

export default React.memo(HeaderWithButton);
