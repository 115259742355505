import { useSelector } from "react-redux";
import { Col, Row, Card } from "reactstrap";
import { UserRoleType } from "../../constants";
import { CustomButton } from "components/CustomButton";
import { CASE_STATUS } from "constants/CaseStatus";
import { RESPONSE_STATUS } from "constants/ResponseStatus";

const COL_NUM_SIX = 6;
const COL_NUM_THREE = 3;

export default function CaseManagementHeader({ data, onButtonClick }) {
    const userType = useSelector((store) => store?.userAuth?.user?.type);
    const isChatButtonDisabled = Boolean(
        data?.trademarkStepsStatus == RESPONSE_STATUS.Pending ||
            data?.trademarkStepsStatus == RESPONSE_STATUS.Awaiting
    );

    return (
        <>
            <Card className="case-detail-main-container">
                {/* <Row> */}
                {/* <Col xl={10} sm={12}> */}
                <Row>
                    <Col md={COL_NUM_THREE}>
                        <div className="case-detail-form-fields-container">
                            <p>{"Case Id"}</p>
                            <div>{data?.caseId || "-"}</div>
                        </div>
                    </Col>
                    <Col md={COL_NUM_SIX}>
                        <div className="case-detail-form-fields-container">
                            <p>{"Title"}</p>
                            <div>{data?.caseTitle || "-"}</div>
                        </div>
                    </Col>
                    <Col md={COL_NUM_THREE} className="d-none d-md-block">
                        <div className="case-detail-form-fields-container">
                            <CustomButton
                                disabled={!isChatButtonDisabled}
                                color="primary"
                                title={"Start Chat"}
                                onClick={onButtonClick}
                                className="login-btn-typo search-btn-header-btn"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={COL_NUM_THREE}>
                        <div className="case-detail-form-fields-container">
                            <p>{"Full Name"}</p>
                            <div>{data?.customerName || "-"}</div>
                        </div>
                    </Col>
                    {userType !== UserRoleType.SubAdmin ? (
                        <Col md={COL_NUM_THREE}>
                            <div className="case-detail-form-fields-container">
                                <p>{"Department Name"}</p>
                                <div>{data?.adminName || "-"}</div>
                            </div>
                        </Col>
                    ) : null}
                    <Col md={COL_NUM_THREE}>
                        <div className="case-detail-form-fields-container">
                            <p>{"Country"}</p>
                            <div>{data?.countryName || "-"}</div>
                        </div>
                    </Col>
                    <Col md={COL_NUM_THREE}>
                        <div className="case-detail-form-fields-container">
                            <p>{"Total Spendings"}</p>
                            <div>${data?.earnings?.toFixed(2) || "-"}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-md-none d-block">
                        <div className="case-detail-form-fields-container">
                            <CustomButton
                                color="primary"
                                title={"Start Chat"}
                                onClick={onButtonClick}
                                className="login-btn-typo search-btn-header-btn w-50"
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
}
