import React, { useState } from "react";
import moment from "moment";
import { Col, Row, Label } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";

import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { SearchBox } from "components/SearchBox";
import { AutoComplete } from "components/AutoComplete";
import {
    CaseStatus,
    ResponseStatus,
    sortingOptions,
    caseManagmentHeader,
    customStyles,
    caseStatusOptions,
    filterOptions,
    responseStatusOptions,
    processStatusOptions,
    countriesOptions,
    stepsName,
    UserRoleType
} from "../../constants";
import { Sorting } from "components/Sorting";
import { METATAGS } from "../../constants";

import { getAllCaseManagement } from "api/api.service";
import detailsIcon from "../../assets/images/aiAPaiT/Table/view-icon.svg";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "helpers/routeHelpers";
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";

const FilterTypes = {
    COUNTRY_NAME: "countryName",
    TRADEMARK_STATUS: "trademarkStatus",
    TRADEMARK_STEP: "trademarkStep"
};
const Listing = () => {
    const userType = useSelector((store) => store?.userAuth?.user?.type);
    const userCountries = useSelector((store) => store?.userAuth?.user?.userCountry);
    const [filters, setFilters] = useState({
        filterType: "",
        statusValue: []
    });
    // if department then filtering out the assigned(active) countries only
    const activeCountries = userCountries
        ?.map((el) => {
            if (el?.country?.status == "ACTIVE") {
                return {
                    label: el?.country?.code
                        ? `${el?.country?.name}(${el?.country?.code})`
                        : `${el?.country?.name}`,
                    value: el?.country?.name
                };
            }
        })
        .filter((el) => el !== undefined);

    const countriesOptionsSpecified = userType === "SUBADMIN" ? activeCountries : countriesOptions;

    // selecting the filter options according to selected by user
    const sortingOptionsEvaluated =
        filters.filterType === FilterTypes.COUNTRY_NAME
            ? countriesOptionsSpecified
            : filters.filterType === FilterTypes.TRADEMARK_STATUS
            ? caseStatusOptions
            : filters.filterType === FilterTypes.TRADEMARK_STEP
            ? processStatusOptions
            : responseStatusOptions;

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        updatePageOnFilterChange,
        data,
        total,
        isFetching
    } = useLocalPaginatedRequest({
        requestFn: getAllCaseManagement,
        params: {
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumns: filters?.filterType }),
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumnsValue: filters?.statusValue?.value })
        },
        deps: [filters.statusValue]
    });

    // autocomplete on change handler
    const handleFilterTypeSelect = (val, valueType) => {
        if (val) {
            if (valueType) {
                setFilters({ ...filters, filterType: val?.value, statusValue: "" });
            } else {
                updatePageOnFilterChange();
                setFilters({ ...filters, statusValue: val });
            }
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CASE_MANAGEMENT}</title>
            </MetaTags>
            <Row className="p-3 case-management-con">
                <Col md={12}>
                    <div className="bg-white d-flex  align-items-center p-3 listing-header-con">
                        <SearchBox
                            iconClass="main-header-search-icon"
                            className="main-header-search-box main-header-search-box-modified"
                            placeholder="Search by customer or admin name"
                            onChange={handleSearchChange}
                            value={searchText}
                        />
                        <div className="d-flex  main-header-filter-box align-items-center main-header-type-filter-modified">
                            <Label className="ms-4 me-3 mb-0">Filter Type</Label>
                            <div className="header-filter">
                                <AutoComplete
                                    customStyles={customStyles.WarehouseIM}
                                    isSearchable={false}
                                    onChange={(val) => handleFilterTypeSelect(val, "valueType")}
                                    classNamePrefix="status-header-search-admin"
                                    options={filterOptions}
                                    placeholder="Select Filter Type"
                                />
                            </div>
                        </div>
                        {filters?.filterType !== "" && (
                            <div className="d-flex  main-header-filter-box  align-items-center main-header-con-modified">
                                <Label className="ms-4 me-3 mb-0 label-filterBy">Filter by</Label>
                                <div className="header-filter">
                                    <AutoComplete
                                        customStyles={customStyles.WarehouseIM}
                                        isSearchable={
                                            filters.filterType === "countryName" ? true : false
                                        }
                                        onChange={(val) => handleFilterTypeSelect(val)}
                                        value={filters.statusValue}
                                        classNamePrefix="status-header-search-admin"
                                        options={sortingOptionsEvaluated}
                                        placeholder="Select From List"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
                <Col md={12}>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: caseManagmentHeader.CASE_ID,
                                className: "cm-case-id",
                                render: (data) => data?.id || "-"
                            },
                            {
                                title: caseManagmentHeader.CUSTOMER_NAME,
                                className: "bold-font cm-cust-name text-truncate ",
                                render: (data) => data?.customerName || "-"
                            },
                            ...(userType !== UserRoleType.SubAdmin
                                ? [
                                      {
                                          title: caseManagmentHeader.SUB_ADMIN_NAME,
                                          className: "cm-subadmin-name bold-font ",
                                          render: (data) => data?.adminName || "-"
                                      }
                                  ]
                                : []),
                            {
                                title: caseManagmentHeader.REGION,
                                className: "cm-region",
                                render: (data) => data?.countryName || "-"
                            },
                            {
                                title: caseManagmentHeader.PROCESS,
                                className: "bold-font cm-process ",
                                render: (data) =>
                                    stepsName.getDisplayTextKey(data?.trademarkStep) || "-"
                            },
                            {
                                title: caseManagmentHeader.FEE_MANAGEMENT,
                                className: "bold-font cm-process ",
                                render: (data) => (
                                    <>
                                        {data?.expiryDate == "" ||
                                        null ||
                                        data?.trademarkStep === stepsName?.Tmcr
                                            ? "-"
                                            : moment(data?.expiryDate).format("YYYY-MM-DD")}
                                    </>
                                )
                            },
                            {
                                title: caseManagmentHeader.LAST_PAYMENT_DATE,
                                className: "bold-font cm-process",
                                render: (data) => (
                                    <>
                                        {/* {data?.trademarkPaymentDate === ""
                                            ? "-"
                                            : moment
                                                  .utc(data?.trademarkPaymentDate)
                                                  .format("DD MMMM, YYYY [at] h:mm A")} */}
                                        {data?.trademarkPaymentDate === ""
                                            ? "-"
                                            : moment(data?.trademarkPaymentDate).format(
                                                  "DD MMMM, YYYY [at] h:mm A"
                                              )}
                                    </>
                                )
                            },
                            {
                                title: caseManagmentHeader.CASE_STATUS,
                                className: "cm-status",
                                render: (data) => {
                                    return (
                                        <div
                                            className={`${CaseStatus.getLabelClass(
                                                data?.trademarkStatus
                                            )} status-rest `}
                                        >
                                            <p>
                                                {CaseStatus.getDisplayTextKey(
                                                    data?.trademarkStatus
                                                ) || "-"}
                                            </p>
                                        </div>
                                    );
                                }
                            },
                            {
                                title: caseManagmentHeader.RESPONSE_STATUS,
                                className: "cm-response-status",
                                render: (data) => {
                                    return (
                                        <div
                                            className={`${ResponseStatus.getLabelClass(
                                                data?.trademarkStepsStatus
                                            )} status-rest`}
                                        >
                                            <p>
                                                {ResponseStatus.getDisplayTextKey(
                                                    data?.trademarkStepsStatus
                                                ) || "-"}
                                            </p>
                                        </div>
                                    );
                                }
                            },
                            {
                                title: caseManagmentHeader.ACTION,
                                className: "cm-action",
                                render: (data) => (
                                    <Link
                                        to={`${APP_ROUTES.CASE_MANAGEMENT_DETAILS}/${data?.id}`}
                                        className=" pointer "
                                    >
                                        <img src={detailsIcon} alt="details icon" />
                                    </Link>
                                )
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            className="pagination-contain "
                            rowLimit={pageSize}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Listing;
